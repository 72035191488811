<template>
	<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 116 102">
		<g id="Layer_1-2">
			<path
				class="cls-1"
				d="M32.68,31.69L1.25,87.41c-.82,1.45-1.25,3.08-1.25,4.75,0,5.34,4.32,9.66,9.66,9.66h22.47c4.65,0,8.93-2.5,11.22-6.55l12.15-21.54-21.02-37.26c-.85-1.51-1.43-3.13-1.8-4.78Z"
			/>
			<path
				class="cls-1"
				d="M114.98,87.41L69.36,6.55c-1.53-2.72-3.92-4.64-6.62-5.67-.05-.02-.1-.04-.16-.06-.56-.21-1.14-.39-1.72-.52-.04,0-.08-.02-.11-.03-.32-.07-.65-.12-.97-.16-.12-.01-.23-.03-.35-.04-.25-.03-.5-.04-.76-.05-.18,0-.37-.02-.55-.02-.19,0-.37,.01-.55,.02-.25,.01-.5,.02-.76,.05-.12,.01-.23,.03-.35,.04-.33,.04-.65,.09-.97,.16-.04,0-.08,.02-.11,.03-1.21,.27-2.41,.72-3.55,1.36-2.05,1.15-3.74,2.84-4.89,4.89l-8.48,15.02c-2.22,3.93-2.22,8.73,0,12.66l19.66,34.85,14.77,26.18c2.28,4.05,6.57,6.55,11.22,6.55h22.47c5.34,0,9.66-4.32,9.66-9.66,0-1.66-.43-3.3-1.25-4.75Z"
			/>
		</g>
	</svg>
</template>
